import logo from './assets/dna_icon4.svg';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import HomePage from './pages/home/Home';
import ContactPage from './pages/contact/Contact';
import { Outlet } from "react-router-dom";

function Layout() {
  return (
      <>
        <Header />
        <div className='App-content'>
          <Outlet />
        </div>
        <Footer />
      </>
  );
}

function Error404() {
  return <h2>404 Not found</h2>;
}

const router = createBrowserRouter([
  {
    element: <Layout/>,
    errorElement: <Error404 />,
    children: [  
      {
        path: '/',
        element: <HomePage />
      },
      {
        path: '/contact',
        element: <ContactPage />
      }
    ]
  }
])

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
