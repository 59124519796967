import './Header.css';
import logo from '../../assets/dna_icon4.svg';

function Header () {
    return (
        <div className='App-header'>
            <div className='App-header-logo'>
                <img src={logo} className="App-logo-small" alt="logo" />
            </div>
            <div className='App-header-text'>
                <text className='title-padding primary-blue'>LifeSci </text><text className='title-padding primary-white'>Digital</text>
            </div>
        </div>
    );
}

export default Header;