import './Home.css';
import logo from '../../assets/dna_icon4.svg';
import cloudIcon from '../../assets/cloud_icon.svg';
import atomIcon from '../../assets/atom_icon2.svg';
import useWindowDimensions from '../../hooks/UseWindowsDimensions';

const Home = () => {
  const { height, width } = useWindowDimensions();

  let container_style = 'App-content-container-small';
  if (width > 1200)
    container_style = 'App-content-container';

  return (
        <div className={container_style}>
          <div className='App-content-title'>
            <div className='App-content-logo'>
              <img src={logo} className="App-logo-large" alt="logo" />
            </div>
            <div className='App-content-name'>
              <div className='App-content-text'>
                <text className='primary-blue'>LifeSci </text><text className='title-padding primary-white'>Digital</text>
              </div>
              <div className='App-content-slogan'>
                <text className='App-content-para-large-text primary-white'>Digitalization of Life Sciences.</text>
              </div>
            </div>
          </div>
          <div className='App-content-para-container'>
            <div className='App-content-para'>
              <div className='App-content-para-icon'>
                <img src={cloudIcon} className="App-logo-large" alt="logo" />
              </div>
              <div className='App-content-para-text'>
                <ul className='primary-white'>
                  <li>Improve process with cloud technology</li>
                  <li>Provide easy access to data</li>
                  <li>Ensure security with proven methodologies</li>
                </ul>
              </div>
            </div>
            <div className='App-content-para'>
              <div className='App-content-para-icon'>
                <img src={atomIcon} className="App-logo-large" alt="logo" />
              </div>
              <div className='App-content-para-text'>
                <ul className='primary-white'>
                  <li>Increase the speed of your process</li>
                  <li>Remove potential for costly errors</li>
                  <li>Provide repeatable and accurate results</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    )
}

export default Home;