import './Footer.css';

function Footer () {
    return (
        <div className='App-footer'>
            <text className='footer-text-padding primary-grey'>2024 LifeSci Digital, All Rights Reserved.</text>
        </div>
    );
}

export default Footer;